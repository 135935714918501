import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Spinner } from "reactstrap";

import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { apiError } from "../../slices/thunk";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate, useSearchParams } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.svg";
import { createSelector } from 'reselect';
import { UserRoleKeys } from "utils/UserManager";
import { registerUser } from "helpers/backend_helper";
import MaskedInput from "react-text-mask";

const Register = props => {

  const phoneNumberMask = [
    "(", /[1-9]/, /\d/, /\d/, ")",
    " ", /\d/, /\d/, /\d/, "-",
    /\d/, /\d/, /\d/, /\d/
  ];

  //meta title
  document.title = "Register | Eckerd Connects -  Admin";

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isRegistering, setIsRegistering] = useState<boolean>(false);
  const [hidePrimaryFields, setHidePrimaryFields] = useState<boolean>(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      inviteCode: '',
      email: '',
      name: '',
      password: '',
      passwordConfirmation: '',
      phoneNumber: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      name: Yup.string().required("Please Enter Your Name"),
      phoneNumber: Yup.string()
          .required("Please enter your phone number")
          .test(
              "isValidPhoneNumber",
              "Phone number must be exactly 10 digits",
              (value) => {
                if (!value) return false;
                const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
                return numericValue.length === 10; // Ensure exactly 10 digits
              }
          ),

      password: Yup.string()
        .min(8, 'Password should be at least 8 characters')
        .matches(RegExp('(.*[a-z].*)'), 'At least lowercase letter')
        .matches(RegExp('(.*[A-Z].*)'), 'At least uppercase letter')
        .matches(RegExp('(.*[0-9].*)'), 'At least one number')
        .matches(RegExp('(.*[-\#\$\.\%\&\~\!\,\?\@\^\*].*)'), 'At least one special character')
        .required("Please Enter Your Password"),
        // .matches(RegExp(`(.*[!@#$%^&*()_+{}|:<>?~=[\];',./"\\].*)`), 'At least one special letter'),
      passwordConfirmation: Yup.string().required("Please Confirm Your Password").oneOf([Yup.ref('password')], 'Password does not match.')
    }),
    onSubmit: (values) => {
      const formattedPhoneNumber = values.phoneNumber.replace(/\D/g, ""); // Removes all non-digit characters
      const payload = {
        name: values.name,
        email: values.email,
        password: values.password,
        phoneNumber: formattedPhoneNumber, // Only 10-digit number sent
        registrationToken: registrationToken
      }

      // Conditionally add classification only for role_admin
      if (userRole === "role_manager") {
        payload['classification'] = classification;
      }



      if (tenantId) {
        payload['tenantId'] = tenantId
      }


      setIsRegistering(true);

      registerUser(userRole, payload)
        .then((response) => {
          if (response.success) {
            navigate('/login')
          }
          toast.error('Registration is not valid.')
        }).catch(() => {
          toast.error('Registration is not valid.')
        }).finally(() => {
          setIsRegistering(false);
        })
      // dispatch(registerUser(values));
    }
  });

 const selectProperties = createSelector(
    (state: any) => state.Account,
    (account) => ({
      user: account.user,
      registrationError: account.registrationError,
      loading: account.loading,
    })
  );

  const { user, registrationError } = useSelector(selectProperties);
  const [registrationToken, setRegistrationToken] = useState<string>();
  const [classification, setClassification] = useState<string>();
  const [tenantId, setTenantId] = useState<string>();
  const [userRole, setUserRole] = useState<UserRoleKeys>(UserRoleKeys.RoleNone);
  const [disablePhoneInput, setDisablePhoneInput] = useState(false); // State to control input disable

  const roles = useMemo(() => {
    return [
      {
        id: UserRoleKeys.RoleNone,
        name: 'Client'
      },
      {
        id: UserRoleKeys.RoleAdmin,
        name: 'Administrator'
      },
      {
        id: UserRoleKeys.RoleManager,
        name: 'Manager'
      },
      {
        id: UserRoleKeys.RoleCorp,
        name: 'Corporate'
      }
    ]
  }, []);

  const formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return "";
    const cleaned = phoneNumber.replace(/\D/g, ""); // Remove non-numeric characters

    if (cleaned.length !== 10) return phoneNumber; // Ensure it's a 10-digit number before formatting

    return `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}-${cleaned.substring(6, 10)}`;
  };

  function parseQueryString(queryString: string) {
    return queryString
      .split('&')
      .reduce((params: any, pair: string) => {
        const [key, value] = pair.split('=');
        params[key] = value
        return params;
      }, {});
  }

  useEffect(() => {
    try {
      const token = searchParams.get('t')
      if (token) {
        const parsedQuery = atob(token)
        setHidePrimaryFields(true);

        const parsedObj = parseQueryString(parsedQuery);
        const emailInvited = parsedObj['email'];
        const registrationToken = parsedObj['token'];
        const classification = parsedObj['classification'];
        const role = parsedObj['role'];
        const tenantId = parsedObj['tenant'];
        const phoneNumberInvited = parsedObj["phoneNumber"]; // Get phone number if present


        if (phoneNumberInvited) {
          const formattedPhoneNumber = formatPhoneNumber(phoneNumberInvited); // Format the phone number before setting it
          validation.setFieldValue("phoneNumber", formattedPhoneNumber);
          setDisablePhoneInput(true); // Disable input only when phone number comes from URL params
        }
        validation.setFieldValue('email', emailInvited);
        setRegistrationToken(registrationToken ?? '');
        setClassification(classification ?? '');
        setTenantId(tenantId ?? '');
        setUserRole(role as UserRoleKeys);
      }
    } catch (e) {
      console.log(e);
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(apiError());
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <ToastContainer />
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Register</h5>
                        <p>Get your Eckerd account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {user && user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}
                      {false && (
                        <div className="mb-3">
                          <Label className="form-label">User Role</Label>
                          <select className="form-select form-control" value={userRole} disabled={hidePrimaryFields} onChange={(e) => {
                            setUserRole(e.target.value as UserRoleKeys)
                          }}>
                            {roles?.map(role => (
                              <option key={role.id} value={role.id}>{role.name}</option>
                            ))}
                          </select>
                        </div>
                      )}
                      {false && (
                        <div className="mb-3">
                          <Label className="form-label">Invite Code</Label>
                          <Input
                            name="inviteCode"
                            type="text"
                            placeholder="Enter invitation code"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.inviteCode || ""}
                            invalid={
                              validation.touched.inviteCode && validation.errors.inviteCode ? true : false
                            }
                          />
                          {validation.touched.inviteCode && validation.errors.inviteCode ? (
                            <FormFeedback type="invalid">{validation.errors.inviteCode}</FormFeedback>
                          ) : null}
                        </div>
                      )}
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          disabled={hidePrimaryFields}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Name</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder="Enter name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name ? true : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Confirm password</Label>
                        <Input
                          name="passwordConfirmation"
                          type="password"
                          placeholder="Confirm your password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.passwordConfirmation || ""}
                          invalid={
                            validation.touched.passwordConfirmation && validation.errors.passwordConfirmation ? true : false
                          }
                        />
                        {validation.touched.passwordConfirmation && validation.errors.passwordConfirmation ? (
                          <FormFeedback type="invalid">{validation.errors.passwordConfirmation}</FormFeedback>
                        ) : null}
                      </div>


                      <div className="mb-3">
                        <Label className="form-label">Phone Number</Label>
                        <MaskedInput
                            mask={phoneNumberMask}
                            className="form-control"
                            placeholder="(123) 456-7890"
                            value={validation.values.phoneNumber || ""}
                            onChange={(e) => {
                              if (!disablePhoneInput) { // Allow editing only if not from params
                                const numericValue = e.target.value.replace(/\D/g, ""); // Keep only digits
                                validation.setFieldValue("phoneNumber", numericValue);
                              }
                            }}
                            onBlur={validation.handleBlur}
                            render={(ref, props) => (
                                <Input
                                    {...props}
                                    innerRef={ref} // Fixes ref issue
                                    name="phoneNumber"
                                    disabled={disablePhoneInput}
                                    type="text"
                                    invalid={!!(validation.touched.phoneNumber && validation.errors.phoneNumber)}
                                />
                            )}
                        />
                        {validation.touched.phoneNumber && validation.errors.phoneNumber ? (
                            <FormFeedback>{validation.errors.phoneNumber}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block d-flex align-items-center"
                          type="submit"
                          disabled={isRegistering}
                        >
                          Register
                          {isRegistering && <Spinner className="ms-2" size="sm" />}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Eckerd{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Eckerd. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;
