import React, { Fragment, useEffect, useState } from "react";
import { Row, Table, Button, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { utils, writeFile } from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import {
    Column,
    Table as ReactTable,
    ColumnFiltersState,
    FilterFn,
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender
} from '@tanstack/react-table';

import { rankItem } from '@tanstack/match-sorter-utils';
import JobListGlobalFilter from "./GlobalSearchFilter";
import { min } from "lodash";

// Column Filter
const Filter = ({ column }: { column: Column<any, unknown>; table: ReactTable<any>; }) => {
    const columnFilterValue = column.getFilterValue();
    return (
        <>
            <DebouncedInput
                type="text"
                value={(columnFilterValue ?? '') as string}
                onChange={value => column.setFilterValue(value)}
                placeholder="Search..."
                className="w-36 border shadow rounded"
                list={column.id + 'list'}
            />
            <div className="h-1" />
        </>
    );
};

// Global Filter
const DebouncedInput = ({ value: initialValue, onChange, debounce = 500, ...props }: { value: string | number; onChange: (value: string | number) => void; debounce?: number; } & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
    const [value, setValue] = useState(initialValue);
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);
        return () => clearTimeout(timeout);
    }, [debounce, onChange, value]);

    return (
        <React.Fragment>
            <Col sm={12}>
                <input {...props} value={value} onChange={e => setValue(e.target.value)} />
            </Col>
        </React.Fragment>
    );
};
export interface ExportConfig {
    fileName: string;
    headers: string[]; // Column headers
    getData: (row: any) => any[]; // Function to transform row data for export
    excludeFields: string[];
}
export interface TableSection {
    title: string;
    filterFn: (row: any) => boolean;
}

export interface TableContainerProps {
    columns?: any;
    data?: any;
    sections?: TableSection[];
    divClassName?: any;
    tableClass?: any;
    theadClass?: any;
    isBordered?: boolean;
    isGlobalFilter?: boolean;
    isPagination?: boolean;
    paginationWrapper?: string;
    SearchPlaceholder?: string;
    pagination?: string;
    handleUserClick?: any;
    handleRowClick?: any;
    buttonClass?: string;
    buttonName?: string;
    isAddButton?: boolean;
    customRightTools?: JSX.Element;
    customLeftTools?: JSX.Element;
    isCustomPageSize?: boolean;
    isJobListGlobalFilter?: boolean;
    isNoData?: boolean;
    noDataText?: string;
    showExportButtons?: boolean;
    exportConfig?: ExportConfig; // ✅ New prop for customization
    pageSize?: number;
    onPageSizeChange?: (size: number) => void;  // ✅ New prop to notify parent
}

const TableContainer = ({
                            columns,
                            data,
                            sections = [],
                            tableClass,
                            theadClass,
                            divClassName,
                            isBordered,
                            isPagination,
                            isGlobalFilter,
                            paginationWrapper,
                            SearchPlaceholder,
                            pagination,
                            buttonClass,
                            buttonName,
                            isAddButton,
                            customLeftTools,
                            customRightTools,
                            isCustomPageSize,
                            handleUserClick,
                            handleRowClick,
                            isJobListGlobalFilter,
                            isNoData,
                            noDataText,
                            showExportButtons = false, // Default to false
                            pageSize = 10,
                            exportConfig,
                            onPageSizeChange
                        }: TableContainerProps) => {
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    useEffect(() => {
        setPageSize(pageSize);
        if (onPageSizeChange) {
            onPageSizeChange(pageSize);  // ✅ Notify the parent component
        }
    }, [pageSize, onPageSizeChange]);

    const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
        const itemRank = rankItem(row.getValue(columnId), value);
        addMeta({ itemRank });
        return itemRank.passed;
    };

    const table = useReactTable({
        columns,
        data,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const {
        getHeaderGroups,
        getRowModel,
        getCanPreviousPage,
        getCanNextPage,
        getPageOptions,
        setPageIndex,
        nextPage,
        previousPage,
        setPageSize,
        getState
    } = table;



    // Listen for pageSize prop changes and update the table state
    useEffect(() => {
        setPageSize(pageSize);
    }, [pageSize, setPageSize]);

    const handleExportCSV = () => {
        if (!exportConfig) return;

        const { fileName, headers, getData, excludeFields = [] } = exportConfig;
        const csvData: string[][] = [];

        // ✅ Remove excluded headers
        const filteredHeaders = headers.filter(header => !excludeFields.includes(header));

        sections.forEach(section => {
            csvData.push([section.title]); // ✅ Include section title
            const sectionRows = data.filter(row => section.filterFn(row));

            sectionRows.forEach(row => {
                const rowData = getData(row);
                const filteredRowData = rowData.filter((_, index) => !excludeFields.includes(headers[index]));
                csvData.push(filteredRowData.map(String));
            });
        });

        const csvContent = [filteredHeaders, ...csvData].map(row => row.join(",")).join("\n");
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${fileName}.csv`;
        link.click();
    };




    const handleExportExcel = () => {
        if (!exportConfig) return;

        const { fileName, headers, getData, excludeFields = [] } = exportConfig;
        const worksheetData: string[][] = [];

        // ✅ Remove excluded headers
        const filteredHeaders = headers.filter(header => !excludeFields.includes(header));
        worksheetData.push(filteredHeaders); // ✅ Add headers

        sections.forEach(section => {
            worksheetData.push([section.title]); // ✅ Include section title
            const sectionRows = data.filter(row => section.filterFn(row));

            sectionRows.forEach(row => {
                const rowData = getData(row);
                const filteredRowData = rowData.filter((_, index) => !excludeFields.includes(headers[index]));
                worksheetData.push(filteredRowData.map(String));
            });
        });

        const worksheet = utils.aoa_to_sheet(worksheetData);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Report");

        writeFile(workbook, `${fileName}.xlsx`);
    };




    const handleExportPDF = () => {
        if (!exportConfig) return;

        const { fileName, headers, getData, excludeFields = [] } = exportConfig;
        const doc = new jsPDF();
        const pdfData: string[][] = [];

        // ✅ Remove excluded headers
        const filteredHeaders = headers.filter(header => !excludeFields.includes(header));

        sections.forEach(section => {
            pdfData.push([section.title]); // ✅ Include section title
            const sectionRows = data.filter(row => section.filterFn(row));

            sectionRows.forEach(row => {
                const rowData = getData(row);
                const filteredRowData = rowData.filter((_, index) => !excludeFields.includes(headers[index]));
                pdfData.push(filteredRowData.map(String));
            });
        });

        (doc as any).autoTable({
            head: [filteredHeaders], // ✅ Use filtered headers
            body: pdfData,
            startY: 20,
        });

        doc.save(`${fileName}.pdf`);
    };


    const handlePrint = () => {
        if (!exportConfig) return;

        const { headers, getData, excludeFields = [] } = exportConfig;
        let printContent = `
        <html>
        <head>
            <title>Report</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    margin: 20px;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                }
                th, td {
                    border-bottom: 1px solid #ddd; /* ✅ Light border only on the bottom */
                    padding: 8px;
                    text-align: left;
                }
                th {
                    background-color: #f4f4f4;
                    font-weight: bold;
                }
                tr:hover {
                    background-color: #f1f1f1;
                }
                .section-title {
                    background-color: #005f9a;
                    color: white;
                    font-weight: bold;
                    padding: 10px;
                    text-align: left;
                }
            </style>
        </head>
        <body>
        <h2>Report Table</h2>
        <table>
        <thead>
            <tr>${headers.filter(header => !excludeFields.includes(header)).map(header => `<th>${header}</th>`).join("")}</tr>
        </thead>
        <tbody>
    `;

        sections.forEach(section => {
            printContent += `<tr class="section-title"><td colspan="${headers.length}">${section.title}</td></tr>`;
            const sectionRows = data.filter(row => section.filterFn(row));

            sectionRows.forEach(row => {
                const rowData = getData(row);
                const filteredRowData = rowData.filter((_, index) => !excludeFields.includes(headers[index]));

                printContent += `<tr>${filteredRowData.map(value => `<td>${value}</td>`).join("")}</tr>`;
            });
        });

        printContent += "</tbody></table></body></html>";

        const printWindow = window.open("", "_blank");
        if (printWindow) {
            printWindow.document.write(printContent);
            printWindow.document.close();
            printWindow.print();
        } else {
            alert("Popup blocked! Please allow popups for printing.");
        }
    };





    return (
        <Fragment>
            <Row className="mb-2">
                {isCustomPageSize && (
                    <Col sm={2}>
                        <select
                            className="form-select pageSize mb-2"
                            value={table.getState().pagination.pageSize}
                            onChange={e => {
                                table.setPageSize(Number(e.target.value))
                                if (onPageSizeChange) {
                                    onPageSizeChange(Number(e.target.value));  // ✅ Notify parent when user changes page size
                                }
                            }}
                        >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </Col>
                )}

                {isGlobalFilter && (
                    <DebouncedInput
                        value={globalFilter ?? ''}
                        onChange={value => setGlobalFilter(String(value))}
                        className="form-control search-box me-2 mb-2 d-inline-block"
                        placeholder={SearchPlaceholder}
                    />
                )}

                {isJobListGlobalFilter && <JobListGlobalFilter setGlobalFilter={setGlobalFilter} />}

                {customLeftTools && (
                    <Col sm="2">
                        <div className="text-sm-start">
                            {customLeftTools}
                        </div>
                    </Col>
                )}

                {isAddButton && (
                    <Col>
                        <div className="text-sm-end mt-2 mt-sm-0">
                            <Button type="button" className={buttonClass} onClick={handleUserClick}>
                                <i className="mdi mdi-plus me-1"></i> {buttonName}
                            </Button>
                        </div>
                    </Col>
                )}

                {customRightTools && (
                    <Col sm="auto">
                        <div className="text-sm-end">
                            {customRightTools}
                        </div>
                    </Col>
                )}
            </Row>

            <div className={divClassName ? divClassName : "table-responsive"} style={{ minHeight: '300px' }}>
                <Table hover className={tableClass} bordered={isBordered}>
                    <thead className={theadClass}>
                    {getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id} colSpan={header.colSpan} className={`${header.column.columnDef.enableSorting ? "sorting sorting_desc" : ""}`}>
                                    {header.isPlaceholder ? null : (
                                        <Fragment>
                                            <div
                                                {...{
                                                    className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                                                    onClick: header.column.getToggleSortingHandler(),
                                                }}
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {{
                                                    asc: '',
                                                    desc: '',
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </div>
                                            {header.column.getCanFilter() && (
                                                <div>
                                                    <Filter column={header.column} table={table} />
                                                </div>
                                            )}
                                        </Fragment>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>

                    <tbody>
                    {sections.length > 0 ? (
                        sections.map((section, sectionIndex) => {
                            // ✅ Get only paginated rows
                            const paginatedRows = table.getPaginationRowModel().rows;
                            const sectionRows = paginatedRows.filter(row => section.filterFn(row.original));

                            return sectionRows.length > 0 ? ( // ✅ Only render sections with data
                                <Fragment key={`${sectionIndex}-${section.title}`}>
                                    <tr className="table-primary">
                                        <th style={{ color: 'white', backgroundColor: "#005f9a"}} colSpan={columns.length}>
                                            {section.title}
                                        </th>
                                    </tr>
                                    {sectionRows.map((row, rowIndex) => (
                                        <tr key={`${sectionIndex}-${row.id || rowIndex}`}>
                                            {row.getVisibleCells().map(cell => (
                                                <td key={`${sectionIndex}-${row.id || rowIndex}-${cell.id}`} onClick={() => {handleRowClick && handleRowClick(cell.row.original)}}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </Fragment>
                            ) : null;
                        })
                    ) : (
                        table.getPaginationRowModel().rows.map(row => ( // ✅ Apply pagination globally
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => (
                                    <td key={cell.id} onClick={() => {handleRowClick && handleRowClick(cell.row.original)}}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        ))
                    )}
                    </tbody>

                </Table>
            </div>

            {isPagination && (
                <Row>
                    <Col sm={12} md={5}>
                        <div className="dataTables_info">
                            Showing {min([getState().pagination.pageSize, data.length])} of {data.length} Results
                        </div>
                    </Col>
                    <Col sm={12} md={7}>
                        <div className={paginationWrapper}>
                            <ul className={pagination}>
                                <li className={`paginate_button page-item previous ${!getCanPreviousPage() ? "disabled" : ""}`}>
                                    <Link to="#" className="page-link" onClick={previousPage}><i className="mdi mdi-chevron-left"></i></Link>
                                </li>
                                {getPageOptions().map((item: any, key: number) => (
                                    <li key={key} className={`paginate_button page-item ${getState().pagination.pageIndex === item ? "active" : ""}`}>
                                        <Link to="#" className="page-link" onClick={() => setPageIndex(item)}>{item + 1}</Link>
                                    </li>
                                ))}
                                <li className={`paginate_button page-item next ${!getCanNextPage() ? "disabled" : ""}`}>
                                    <Link to="#" className="page-link" onClick={nextPage}><i className="mdi mdi-chevron-right"></i></Link>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            )}

            {showExportButtons && (
                <Row className="mt-3">
                    <Col>
                        <Button onClick={handleExportCSV} className="btn btn-primary me-2">
                            Export CSV
                        </Button>
                        <Button onClick={handleExportExcel} className="btn btn-success me-2">
                            Export Excel
                        </Button>
                        <Button onClick={handleExportPDF} className="btn btn-danger me-2">
                            Export PDF
                        </Button>
                        <Button onClick={handlePrint} className="btn btn-info">
                            Print
                        </Button>
                    </Col>
                </Row>
            )}
        </Fragment>
    );
};

export default TableContainer;
