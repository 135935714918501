import React, {useEffect, useMemo, useState} from "react";
import { Link, useNavigate } from "react-router-dom";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { addCampaign, addCampaignRule, deleteCampaign, getCampaigns, getRules, removeCampaignRule, updateCampaign } from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import DeleteModal from "Components/Modal/DeleteModal";
import CampaignModal from "Components/Modal/CampaignModal";
import { convertUTC2Local } from "utils";

interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}


const CampaignsPage = ({title}) => {
    const [campaignsData, setCampaignsData] = useState<any>([]);
    const [rulesData, setRulesData] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();
    const [activeStatus, setActiveStatus] = useState<number>(1);

    function toggleNewModal() {
        setNewModalIsOpen(!newModalIsOpen);
        removeBodyCss();
    }

    function toggleEditModal() {
        setEditModalIsOpen(!editModalIsOpen);
        removeBodyCss();
    }

    function toggleDeleteModal () {
        setDeleteModalIsOpen(!deleteModalIsOpen);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleCreateCampaign = (newCampaign: any, ruleOptions: any) => {
        const createCampaign = async () => {
            try {
                const response = await addCampaign(newCampaign); // Add parameters if needed
                if (response.success) {

                    setCampaignsData((state) => [...state, response.data]);
                    toast.success("Created new campaign.");

                    if (ruleOptions.addRuleIds.length > 0) {
                        // Add campaign rules
                        for (const ruleId of ruleOptions.addRuleIds) {
                            const addRuleResponse = await addCampaignRule(response.data.id, ruleId);
                            if (!addRuleResponse.success) {
                                // Display error notification
                                toast.error(addRuleResponse.error || `Failed to add rule to campaign w/ID ${ruleId}.`);
                            } else {
                              toast.success("Added new rule to campaign.");
                            }
                        }
                    }
                    toggleNewModal();
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to create campaign.");
                }
            } catch (error) {
                toast.error(String(error) ?? "Failed to create campaign.");
            }
        };

        createCampaign();
    }

    const handleEditCampaign = (campaign: any, ruleOptions: any) => {
        const modifyCampaign = async () => {
            try {
                const response = await updateCampaign(campaign); // Add parameters if needed
                if (response.success) {

                    setCampaignsData((state) => {
                        const findIdx = state.findIndex(item => item?.id === campaign.id);
                        if (!isNaN(findIdx)) {
                            state[findIdx] = campaign
                        }

                        return [...state]
                    });

                    if (ruleOptions.addRuleIds.length > 0) {
                      // Add campaign rules
                      for (const ruleId of ruleOptions.addRuleIds) {
                        const addRuleResponse = await addCampaignRule(response.data.id, ruleId);
                        if (!addRuleResponse.success) {
                            // Display error notification
                            toast.error(addRuleResponse.error || `Failed to add rule to campaign w/ID ${ruleId}.`);
                        } else {
                          toast.success("Added new rule to campaign.");
                        }
                    }
                    }
                    if (ruleOptions.removeRuleIds.length > 0) {
                      // Add campaign rules
                      for (const ruleId of ruleOptions.removeRuleIds) {
                        const removeRuleResponse = await removeCampaignRule(response.data.id, ruleId);
                        if (!removeRuleResponse.success) {
                            // Display error notification
                            toast.error(removeRuleResponse.error || `Failed to remove rule to campaign w/ID ${ruleId}.`);
                        } else {
                          toast.success("Removed rule from campaign.");
                        }
                    }
                  }
                    toggleEditModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to update campaign.");
                }
            } catch (error) {

                toast.error("Failed to update campaign.");
            }
        };

        modifyCampaign();
    }

    const handleDeleteCampaign = () => {
        const removeCampaign = async () => {
            try {
                const response = await deleteCampaign(valueToBeModified.id); // Add parameters if needed
                if (response.success) {

                    setCampaignsData((state) => [...state.filter(item => item.id !== valueToBeModified.id)]);
                    toggleDeleteModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to remove campaign.");
                }
            } catch (error) {

                toast.error("Failed to remove campaign.");
            }
        }

        if (!isNaN(valueToBeModified?.id)) {
            removeCampaign()
        }
    }
    
    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                const response = await getCampaigns(activeStatus); // Add parameters if needed
                if (response.success) {

                    setCampaignsData(response.data.campaigns);
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to fetch campaigns.");
                }
            } catch (error) {

                toast.error("Failed to fetch campaigns.");
            }
        };
        const fetchRules = async () => {
            try {
                const response = await getRules(); // Add parameters if needed
                if (response.success) {

                    setRulesData(response.data.rules);
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to fetch rules.");
                }
            } catch (error) {

                toast.error("Failed to fetch rules.");
            }
        };
        fetchCampaigns();
        // no need to fetch rules if the status is history
        if (activeStatus) {
          fetchRules();
        }

    }, [activeStatus])
    const columns: column[] = useMemo(
        () => {
          const columns: any = [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Name',
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Description',
                accessorKey: 'description',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Start At',
                accessorKey: 'startDate',
                cell: (info) => new Date(convertUTC2Local(info.getValue())).toLocaleDateString(),
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'End At',
                accessorKey: 'endDate',
                cell: (info) => convertUTC2Local(info.getValue()) ? new Date(convertUTC2Local(info.getValue())).toLocaleDateString() : '',
                enableColumnFilter: false,
                enableSorting: true,
            }
          ];
          // TODO: this should actually be available for all, but the prense of edit/delete function should be limited if deletedAt is not null
          if (activeStatus) {
            columns.push({
              header: 'Action',
              accessorKey: 'action',
              enableColumnFilter: false,
              enableSorting: true,
              cell: (cellProps) => {
                  return (
                      <div className="d-flex gap-3">
                          <Link to="#" className="text-success" onClick={() => {
                              setValueToBeModified(cellProps.row.original)
                              toggleEditModal()
                          }}>
                          <i className="mdi mdi-pencil font-size-18" id="editTooltip" />
                          <UncontrolledTooltip placement="top" target="editTooltip">
                              Edit
                          </UncontrolledTooltip>
                          </Link>
                          <Link to="#" className="text-danger" onClick={() => {
                              setValueToBeModified(cellProps.row.original)
                              toggleDeleteModal()
                          }}>
                          <i className="mdi mdi-delete font-size-18" id="deleteTooltip" />
                          <UncontrolledTooltip placement="top" target="deleteTooltip">
                              Delete
                          </UncontrolledTooltip>
                          </Link>
                      </div>
                  )
              }
            })
          }
        return columns;
      },
        [toggleDeleteModal, toggleEditModal]
    );

    const statusRadioGroups = useMemo(() => {
        return (
            <Row className="justify-content-end">
                <Col xs="auto" className="btn-group" role="state">
                    <input type="radio" className="btn-check" name="state" id="active" autoComplete="off" defaultChecked onClick={() => setActiveStatus(1)} />
                    <label className="btn btn-outline-secondary" htmlFor="active">Active</label>

                    <input type="radio" className="btn-check" name="state" id="history" autoComplete="off" onClick={() => setActiveStatus(0)} />
                    <label className="btn btn-outline-secondary" htmlFor="history">History</label>
                </Col>
            </Row>
        )
    }, []);
    
    //meta title
    document.title = `${title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={campaignsData || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isAddButton={true}
                                                    isCustomPageSize={true}
                                                    handleUserClick={toggleNewModal}
                                                    SearchPlaceholder="Search campaigns..."
                                                    pagination="pagination"
                                                    buttonClass="btn btn-success btn-rounded"
                                                    buttonName=" Create New Campaign"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    customLeftTools={
                                                        statusRadioGroups
                                                    }
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <CampaignModal rules={rulesData} isOpen={newModalIsOpen} toggle={toggleNewModal} onSave={handleCreateCampaign} />
                            {activeStatus && <CampaignModal rules={rulesData} isOpen={editModalIsOpen} isEdit data={valueToBeModified} toggle={toggleEditModal} onSave={handleEditCampaign} />}
                            <DeleteModal
                                isOpen={deleteModalIsOpen}
                                toggle={toggleDeleteModal}
                                message={<p>Are you sure you want to delete this campaign? This action cannot be undone.</p>}
                                onConfirmDelete={handleDeleteCampaign}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default CampaignsPage;
