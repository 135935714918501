import React, {useEffect, useMemo, useState} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { addCampaign, deleteCampaign, getCampaignStandings, getCampaigns, updateCampaign } from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, CardTitle, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import { convertUTC2Local } from "utils";
import {userManager} from "../../../utils/UserManager";
const currentUser = userManager;
interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}


const StandingViewPage = ({title}) => {
    const params = useParams();
    const [campaignData, setCampaignData] = useState<any>({});
    const [clientStandingsData, setClientStandingsData] = useState<any[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();
    const { campaignId } = params;

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    useEffect(() => {
        if (!isNaN(Number(campaignId))) {
            const fetchAllCampaignStandings = async () => {
                let allClientStandings: any[] = [];
                let offset = 0;
                const limit = 50;
                let hasMoreData = true;
                let firstCampaignData: typeof campaignData | null = null; // ✅ Explicitly typed

                try {
                    while (hasMoreData) {
                        const response = await getCampaignStandings(Number(campaignId), offset, limit);

                        if (response.success) {
                            const newCampaignData = response.data.campaignStandings;
                            const newClientStandings = newCampaignData?.clientStandings || [];

                            if (!firstCampaignData) {
                                firstCampaignData = { ...newCampaignData }; // ✅ Clone to avoid mutation
                            }

                            allClientStandings = [...allClientStandings, ...newClientStandings];

                            if (newClientStandings.length < limit) {
                                hasMoreData = false; // Stop fetching when last batch is < 50
                            } else {
                                offset += limit;
                            }
                        } else {
                            toast.error(response.error || "Failed to fetch campaign standings.");
                            hasMoreData = false;
                        }
                    }

                    if (firstCampaignData) {
                        setCampaignData({
                            ...firstCampaignData,
                            clientStandings: allClientStandings,
                            rules: firstCampaignData.rules || [] // ✅ Ensures rules exist
                        });
                        setClientStandingsData(allClientStandings);
                    }
                } catch (error) {
                    toast.error("Failed to fetch campaign standings.");
                } finally {
                    setLoading(false);
                }
            };

            setLoading(true);
            fetchAllCampaignStandings();
        }
    }, [campaignId]);



    const ruleColumns: column[] = useMemo(
      () => [
        {
          header: 'Name',
          accessorKey: 'name',
          enableColumnFilter: false,
          enableSorting: true,
        },
        {
          header: 'Points',
          accessorKey: 'points',
          enableColumnFilter: false,
          enableSorting: true,
          cell: (cellProps) => isNaN(Number(cellProps.getValue())) ? '-' : cellProps.getValue()
        },
        {
          header: 'Max Points in 7 days',
          accessorKey: 'maxPoints7Days',
          enableColumnFilter: false,
          enableSorting: true,
          cell: (cellProps) => isNaN(Number(cellProps.getValue())) ? '-' : cellProps.getValue()
        },
        {
          header: 'Max Points in 45 days',
          accessorKey: 'maxPoints45Days',
          enableColumnFilter: false,
          enableSorting: true,
          cell: (cellProps) => isNaN(Number(cellProps.getValue())) ? '-' : cellProps.getValue()
        },
        {
          header: 'Max Usage in 7 days',
          accessorKey: 'maxUsage7Days',
          enableColumnFilter: false,
          enableSorting: true,
          cell: (cellProps) => isNaN(Number(cellProps.getValue())) ? '-' : cellProps.getValue()
        },
        {
          header: 'Max Usage in 45 days',
          accessorKey: 'maxUsage45Days',
          enableColumnFilter: false,
          enableSorting: true,
          cell: (cellProps) => isNaN(Number(cellProps.getValue())) ? '-' : cellProps.getValue()
        },
      ],
      [campaignData]
    );

    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'clientId',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Name',
                accessorKey: 'client.name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Points',
                accessorKey: 'rulePointsMapping',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                  return (
                    <TableContainer
                      columns={[
                        {
                          header: 'Rule Name',
                          accessorKey: 'ruleId',
                          enableColumnFilter: false,
                          enableSorting: true,
                          cell: (cellData) => {
                            const ruleId = cellData.getValue();

                            const findRule = (campaignData.rules ?? []).find(item => item.id === ruleId);

                            return findRule.name ?? ''
                          }
                        },
                        {
                          header: 'Points',
                          accessorKey: 'points',
                          enableColumnFilter: false,
                          enableSorting: true,
                          cell: (cellData) => {
                            const ruleId = cellData.row.original.ruleId;

                            const findRule = (campaignData.rules ?? []).find(item => item.id === ruleId);


                              return (cellData.getValue() > findRule.maxPoints45Days ? findRule.maxPoints45Days : cellData.getValue() ) + ' / ' + findRule.maxPoints45Days;


                          }
                        },
                      ]}
                      data={cellProps.getValue() || []}
                      isGlobalFilter={false}
                      isPagination={false}
                      isCustomPageSize={false}
                      tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-border dataTable no-footer dtr-inline"
                      theadClass="table-light"
                    />
                  )
                }
            },
            {
              header: 'Status',
              accessorKey: 'status',
              enableColumnFilter: false,
              enableSorting: true,
              cell: (cellProps) => {
                const rulePointsMapping = cellProps.row.original.rulePointsMapping;

                const mappings = rulePointsMapping ?? [];

                let percentage = 0;
                let totalPoints = 0;
                let maxPoints = 0;
                mappings.forEach(map => {
                  const ruleId = map.ruleId;
                  const findRule = (campaignData.rules ?? []).find(item => item.id === ruleId);

                  totalPoints = totalPoints + map.points;
                  maxPoints = maxPoints + findRule.maxPoints45Days;

                  percentage = percentage + map.points / (findRule.maxPoints45Days || 1)
                })


                return  totalPoints +'/' + maxPoints + ' ~ ' + ((percentage / mappings.length) * 100).toFixed(2) + '%'  ;
              }
            },
            {
                header: 'Action',
                accessorKey: 'client.email',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const email = cellProps.getValue();
                    return (
                        <div className="d-flex gap-3">
                            <Link to={`mailto:${email}`} className="text-primary">
                            <i className="mdi mdi-mail font-size-18" id="viewTooltip" />
                            <UncontrolledTooltip placement="top" target="viewTooltip">
                                Email
                            </UncontrolledTooltip>
                            </Link>
                        </div>
                    )
                }
            },
            // Add more columns as needed
        ],
        [clientStandingsData, campaignData]
    );

    //meta title
    document.title = `${title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <>
                                  <Row>
                                    <Col lg={3}>
                                      <Row>
                                        <Col>
                                          <Card>
                                            <CardBody>
                                              <CardTitle>Campaign</CardTitle>
                                              <h5 className="font-size-15">{campaignData.campaign?.name}</h5>

                                              <p className="text-muted">
                                                {campaignData.campaign?.description}
                                              </p>

                                              <Row className="task-dates">
                                                <Col sm="4" xs="6">
                                                  <div className="mt-4">
                                                    <h5 className="font-size-14">
                                                      <i className="bx bx-calendar me-1 text-primary" /> Start Date
                                                    </h5>
                                                    <p className="text-muted mb-0">{campaignData.campaign?.startDate}</p>
                                                  </div>
                                                </Col>

                                                <Col sm="4" xs="6">
                                                  <div className="mt-4">
                                                    <h5 className="font-size-14">
                                                      <i className="bx bx-calendar-check me-1 text-primary" /> End
                                                      Date
                                                    </h5>
                                                    <p className="text-muted mb-0">{campaignData.campaign?.endDate}</p>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </CardBody>
                                          </Card>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <Card>
                                            <CardBody>
                                              <CardTitle>
                                                Clients Enrolled
                                              </CardTitle>
                                              <h5 className="font-size-15">{campaignData.clientStandings?.length ?? 0}</h5>
                                            </CardBody>
                                          </Card>
                                        </Col>
                                      </Row>
                                    </Col>
                                    {!currentUser.isManager() &&

                                  (<Col lg={9}>
                                      <Card>
                                        <CardBody>
                                          <CardTitle>
                                            Rules:
                                          </CardTitle>
                                          <TableContainer
                                            columns={ruleColumns}
                                            data={campaignData.rules || []}
                                            isGlobalFilter={false}
                                            isPagination={false}
                                            isCustomPageSize={false}
                                            tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                            theadClass="table-light"
                                          />
                                        </CardBody>
                                      </Card>
                                    </Col>)}
                                  </Row>
                                  <Row>
                                      <Col lg={12}>
                                          <Card>
                                              <CardBody>
                                                  <CardTitle>
                                                    Campaign list
                                                  </CardTitle>
                                                  <TableContainer
                                                      columns={columns}
                                                      data={clientStandingsData || []}
                                                      isGlobalFilter={true}
                                                      isPagination={true}
                                                      isCustomPageSize={true}
                                                      SearchPlaceholder="Search standings..."
                                                      pagination="pagination"
                                                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                      tableClass="project-list-table align-start table-nowrap dt-responsive nowrap w-100 table-border dataTable no-footer dtr-inline"
                                                      theadClass="table-light"
                                                  />
                                              </CardBody>
                                          </Card>
                                      </Col>
                                  </Row>
                                </>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default StandingViewPage;
