import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Table,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import Breadcrumbs from "Components/Common/Breadcrumb";
import {toast, ToastContainer} from "react-toastify";
import {
    getNotificationGroups,
    createNotificationGroup,
    updateNotificationGroup,
    deleteNotificationGroup,
    assignClientsToGroup, removeClientsFromGroup,
} from "helpers/backend_helper";
import { getClients } from "helpers/backend_helper";
import { userManager } from "../../../utils/UserManager";

interface NotificationGroup {
    id: number;
    name: string;
    description: string | null;
    clientIds: number[];
}

interface Client {
    id: number;
    name: string;
    email: string;
}

/*
  ClientSelection renders a paginated, searchable table.
  Clicking a row toggles selection via the onToggle callback.
*/
const ClientSelection: React.FC<{
    clients: Client[];
    selectedClientIds: number[];
    onToggle: (clientId: number) => void;
}> = ({ clients, selectedClientIds, onToggle }) => {
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const filteredClients = clients.filter(
        (client) =>
            client.name.toLowerCase().includes(search.toLowerCase()) ||
            client.email.toLowerCase().includes(search.toLowerCase())
    );
    const totalPages = Math.ceil(filteredClients.length / pageSize) || 1;
    const displayedClients = filteredClients.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        setCurrentPage(1); // Reset page on search
    };

    const handlePrev = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
    const handleNext = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));

    // Determines if all displayed clients are selected
    const allDisplayedSelected = displayedClients.every(client =>
        selectedClientIds.includes(client.id)
    );

    // Handle the select-all checkbox change
    const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            // Add all displayed clients that are not yet selected
            displayedClients.forEach((client) => {
                if (!selectedClientIds.includes(client.id)) {
                    onToggle(client.id);
                }
            });
        } else {
            // Remove all displayed clients that are selected
            displayedClients.forEach((client) => {
                if (selectedClientIds.includes(client.id)) {
                    onToggle(client.id);
                }
            });
        }
    };

    return (
        <div>
            <FormGroup>
                <Input
                    type="text"
                    id="clientSearch"
                    value={search}
                    onChange={handleSearchChange}
                    placeholder="Search by name or email"
                />
            </FormGroup>
            <Table bordered responsive size="sm">
                <thead>
                <tr>
                    <th>
                        <input
                            type="checkbox"
                            checked={allDisplayedSelected}
                            onChange={handleSelectAllChange}
                        />
                    </th>
                    <th>Name</th>
                    <th>Email</th>
                </tr>
                </thead>
                <tbody>
                {displayedClients.map((client) => (
                    <tr
                        key={client.id}
                        onClick={() => onToggle(client.id)}
                        style={{ cursor: "pointer" }}
                    >
                        <td>
                            <input
                                type="checkbox"
                                readOnly
                                checked={selectedClientIds.includes(client.id)}
                            />
                        </td>
                        <td>{client.name}</td>
                        <td>{client.email}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <div className="d-flex justify-content-between align-items-center">
                <Button color="secondary" size="sm" onClick={handlePrev} disabled={currentPage === 1}>
                    Previous
                </Button>
                <span>
          Page {currentPage} of {totalPages}
        </span>
                <Button
                    color="secondary"
                    size="sm"
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </div>
        </div>
    );
};

const NotificationGroupsPage = ({ title }) => {
    const [groups, setGroups] = useState<NotificationGroup[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const currentUser = userManager;

    // Modal state for create/edit group
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [editingGroup, setEditingGroup] = useState<NotificationGroup | null>(null);
    const [groupForm, setGroupForm] = useState<{
        name: string;
        description: string;
        clientIds: number[];
    }>({
        name: "",
        description: "",
        clientIds: [],
    });
    // State for disabling submit button while processing
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    // Modal state for delete confirmation
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [groupToDelete, setGroupToDelete] = useState<NotificationGroup | null>(null);

    // Toggle modals
    const toggleModal = () => setModalOpen(!modalOpen);
    const toggleDeleteModal = () => setDeleteModalOpen(!deleteModalOpen);

    const fetchAllClients = async () => {
        let allClients: any[] = [];
        let offset = 0;
        const limit = 50;
        let hasMoreData = true;

        try {
            while (hasMoreData) {
                const user = currentUser.getUser();
                const firstGroupId =
                    user &&
                    user.dbUser &&
                    Array.isArray(user.dbUser.groupIds) &&
                    user.dbUser.groupIds.length > 0
                        ? user.dbUser.groupIds[0]
                        : null;

                const response = await getClients(1, offset, limit, firstGroupId);
                if (response.success) {
                    const newClients = response.data.clients || [];
                    if (newClients.length === 0) {
                        hasMoreData = false;
                    } else {
                        allClients = [...allClients, ...newClients];
                        offset += limit;
                    }
                } else {
                    toast.error(response.error || "Failed to fetch clients.");
                    hasMoreData = false;
                }
            }


            setClients(allClients);
        } catch (error) {
            toast.error("Failed to fetch clients.");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const groupsResponse = await getNotificationGroups();
                if (groupsResponse.success) {
                    setGroups(groupsResponse.data.notificationGroups || []);
                } else {
                    toast.error(groupsResponse.error || "Failed to fetch notification groups.");
                }
                fetchAllClients();
            } catch (error) {
                toast.error("Error fetching data.");
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    // Handles text input changes (for name, description)
    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setGroupForm((prev) => ({ ...prev, [name]: value }));
    };

    // Toggle client selection on click
    const toggleClientSelection = (clientId: number) => {
        setGroupForm((prev) => {
            const isSelected = prev.clientIds.includes(clientId);
            return {
                ...prev,
                clientIds: isSelected
                    ? prev.clientIds.filter((id) => id !== clientId)
                    : [...prev.clientIds, clientId],
            };
        });
    };

    const openCreateModal = () => {
        setEditingGroup(null);
        setGroupForm({ name: "", description: "", clientIds: [] });
        toggleModal();
    };

    const openEditModal = (group: NotificationGroup) => {
        setEditingGroup(group);
        setGroupForm({
            name: group.name,
            description: group.description || "",
            clientIds: group.clientIds,
        });
        toggleModal();
    };

    const handleSubmit = async () => {
        // Validate that name is provided
        if (!groupForm.name.trim()) {
            toast.error("Please fill in the group name.");
            return;
        }
        // // Validate that description is provided
        // if (!groupForm.description.trim()) {
        //     toast.error("Please fill in the group description.");
        //     return;
        // }
        // Check if at least one client is selected
        if (groupForm.clientIds.length === 0) {
            toast.error("Please select at least one client.");
            return;
        }
        if (isSubmitting) return;
        setIsSubmitting(true);
        toast.info("Please wait...", { autoClose: 2000 });
        try {
            if (editingGroup) {
                // Compare form values to determine if anything changed
                const nameChanged = groupForm.name.trim() !== (editingGroup.name || "").trim();
                const descChanged = groupForm.description.trim() !== (editingGroup.description || "").trim();
                // Determine clients to add and remove
                const newClients = groupForm.clientIds.filter(id => !editingGroup.clientIds.includes(id));
                const removedClients = editingGroup.clientIds.filter(id => !groupForm.clientIds.includes(id));

                // If nothing has changed, do nothing.
                if (!nameChanged && !descChanged && newClients.length === 0 && removedClients.length === 0) {
                    toast.info("No changes detected.");
                    return;
                }

                // Update group only if name or description has changed
                if (nameChanged || descChanged) {
                    const updatePayload: any = { name: groupForm.name };
                    if (groupForm.description.trim()) {
                        updatePayload.description = groupForm.description;
                    }
                    const updateResponse = await updateNotificationGroup(editingGroup.id, updatePayload);
                    if (updateResponse.success) {
                        toast.success("Notification group updated successfully.");
                    } else {
                        toast.error(updateResponse.error || "Failed to update group.");
                        return;
                    }
                }

                // Process client modifications separately
                if (newClients.length > 0) {
                    const addResponse = await assignClientsToGroup(editingGroup.id, { clientIds: newClients });
                    if (!addResponse.success) {
                        toast.error(addResponse.error || "Failed to add new clients.");
                    } else {
                        toast.success("New clients added.");
                    }
                }
                if (removedClients.length > 0) {
                    // Remove clients from group – ensure you have an API for removal.
                    const removeResponse = await removeClientsFromGroup(editingGroup.id, removedClients);

                    if (!removeResponse.success) {
                        toast.error(removeResponse.error || "Failed to remove clients.");
                    } else {
                        toast.success("Removed clients updated.");
                    }
                }

                // Refresh groups
                const groupsResponse = await getNotificationGroups();
                if (groupsResponse.success) {
                    setGroups(groupsResponse.data.notificationGroups || []);
                }
            } else {
                // Creation
                const createPayload: any = { name: groupForm.name };
                if (groupForm.description.trim()) {
                    createPayload.description = groupForm.description;
                }
                const createResponse = await createNotificationGroup(createPayload);
                if (createResponse.success) {
                    const newGroupId = createResponse.data.id;
                    const assignResponse = await assignClientsToGroup(newGroupId, { clientIds: groupForm.clientIds });
                    if (!assignResponse.success) {
                        toast.error(assignResponse.error || "Clients couldn't be added.");
                    }
                    const groupsResponse = await getNotificationGroups();
                    if (groupsResponse.success) {
                        setGroups(groupsResponse.data.notificationGroups || []);
                    }
                    toast.success("Notification group created successfully.");
                } else {
                    toast.error(createResponse.error || "Failed to create group.");
                    return;
                }
            }
            toggleModal(); // Dismiss modal if all operations succeed
        } catch (error) {
            toast.error("Error saving group.");
        } finally {
            setIsSubmitting(false);
        }
    };



    const confirmDeleteGroup = (group: NotificationGroup) => {
        setGroupToDelete(group);
        toggleDeleteModal();
    };

    const handleDeleteGroup = async () => {
        if (!groupToDelete) return;
        setIsSubmitting(true);
        try {
            const response = await deleteNotificationGroup(groupToDelete.id);
            if (response.success) {
                toast.success("Group deleted successfully.");
                setGroups((prev) => prev.filter((g) => g.id !== groupToDelete.id));
            } else {
                toast.error(response.error || "Failed to delete group.");
            }
        } catch (error) {
            toast.error("Error deleting group.");
        } finally {
            setIsSubmitting(false);
            toggleDeleteModal();
        }
    };

    // Disable submit if already submitting, no client is selected, or name is empty.
    const isSubmitDisabled = isSubmitting || groupForm.clientIds.length === 0 || !groupForm.name.trim();

    document.title = `${title} | Eckerd Connects - Admin`;
    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="Notification Groups" breadcrumbItem="Notification Groups" />
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h4 className="card-title">Notification Groups</h4>
                                    <Button color="primary" onClick={openCreateModal}>
                                        Create New Notification Group
                                    </Button>
                                </div>
                                {isLoading ? (
                                    <p>Loading...</p>
                                ) : groups.length > 0 ? (
                                    <Table bordered responsive>
                                        <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Clients</th>
                                            <th>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {groups.map((group) => (
                                            <tr key={group.id}>
                                                <td>{group.name}</td>
                                                <td>{group.description || "-"}</td>
                                                <td>
                                                    {group.clientIds
                                                        .map((clientId) => {
                                                            const client = clients.find((c) => c.id === clientId);
                                                            return client ? client.name : clientId;
                                                        })
                                                        .join(", ")}
                                                </td>
                                                <td>
                                                    <Button color="secondary" size="sm" onClick={() => openEditModal(group)}>
                                                        Edit
                                                    </Button>{" "}
                                                    <Button color="danger" size="sm" onClick={() => confirmDeleteGroup(group)}>
                                                        Delete
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <div className="text-center">
                                        <p>There are no notification groups created.</p>
                                        <Button color="primary" onClick={openCreateModal}>
                                            Create New Notification Group
                                        </Button>
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/* Modal for Create / Edit Notification Group */}
                <Modal isOpen={modalOpen} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>
                        {editingGroup ? "Edit Notification Group" : "Create New Notification Group"}
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label for="groupName">Name</Label>
                                <Input
                                    type="text"
                                    id="groupName"
                                    name="name"
                                    value={groupForm.name}
                                    onChange={handleFormChange}
                                    placeholder="Enter group name"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="groupDescription">Description</Label>
                                <Input
                                    type="text"
                                    id="groupDescription"
                                    name="description"
                                    value={groupForm.description}
                                    onChange={handleFormChange}
                                    placeholder="Enter group description (optional)"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Select Clients</Label>
                                <ClientSelection
                                    clients={clients}
                                    selectedClientIds={groupForm.clientIds}
                                    onToggle={toggleClientSelection}
                                />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleSubmit} disabled={isSubmitDisabled}>
                            {isSubmitting ? "Please wait..." : editingGroup ? "Update Group" : "Create Group"}
                        </Button>
                        <Button color="secondary" onClick={toggleModal} disabled={isSubmitting}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

                {/* Delete Confirmation Modal */}
                <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal}>
                    <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete the notification group "{groupToDelete?.name}"?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handleDeleteGroup} disabled={isSubmitting}>
                            {isSubmitting ? "Please wait..." : "Delete"}
                        </Button>
                        <Button color="secondary" onClick={toggleDeleteModal} disabled={isSubmitting}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </div>
    );
};

export default NotificationGroupsPage;
