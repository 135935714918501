import React, {useEffect, useMemo, useState} from "react";
import { Link } from "react-router-dom";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { getStartedVideos } from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import Thumbnail from "Components/Common/Thumbnail";

interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}

const HowToPage = ({title}) => {
    const [howToVideoData, setStartedVideosData] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    console.log(howToVideoData);
    useEffect(() => {
        const fetchStartedVideo = async () => {
            try {
                const response = await getStartedVideos("how-to", 0, 50); // Add parameters if needed
                if (response.success) {

                    const sortedVideos = response.data.gettingStartedVideos.sort((a, b) => a.order - b.order);
                    setStartedVideosData(sortedVideos);
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to fetch how-to videos.");
                }
            } catch (error) {

                toast.error("Failed to fetch how-to videos.");
            }
        };
        // fetchManagers();
        fetchStartedVideo();
    }, []);
    const columns: column[] = useMemo(
        () => [
            {
                header: 'Video',
                accessorKey: 'thumbnailUrl',
                cell: (info) => <Thumbnail url={info.getValue()} />,
                enableColumnFilter: false,
                enableSorting: true
            },
            {
                header: 'Title',
                accessorKey: 'title',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Subtitle',
                accessorKey: 'subtitle',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (info) => <div style={{ whiteSpace: 'normal' }}>{info.getValue()}</div>
            },
            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) =>
                      <div className="d-flex gap-2">
                          <Link to="#" className="blue" onClick={() => {
                              window.open(`https://player.vimeo.com/video/${cellProps.row.original.vimeoId}`, '_blank')
                            }}>
                                <i className="mdi mdi-eye font-size-18" id="viewToolTip" />
                                <UncontrolledTooltip placement="top" target="viewToolTip">
                                    Watch
                                </UncontrolledTooltip>
                          </Link>
                      </div>
            },
        ],
        []
    );

    //meta title
    document.title = `${title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={howToVideoData || []}
                                                    isGlobalFilter={false}
                                                    isPagination={true}
                                                    isCustomPageSize={true}
                                                    pagination="pagination"
                                                    buttonClass="btn btn-success btn-rounded"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default HowToPage;
