// File: ReportClientsCompleteGoals.tsx
import React, { useCallback, useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";
import Breadcrumbs from "../../../Components/Common/Breadcrumb";
import SectionTableContainer from "../../../Components/Common/SectionTableContainer";
import FilterComponent from "../../../Components/Common/FilterComponent";
import Spinners from "Components/Common/Spinner";
import CachedImage from "../../../Components/Common/CachedImage";
import { toast } from "react-toastify";
import {getClients, getEventsApi, getManagers, getMoreEventsApi} from "helpers/backend_helper";
import { userManager, UserRoleKeys } from "../../../utils/UserManager";
import { format } from 'date-fns';
import TableContainer from "../../../Components/Common/TableContainer";
export interface ReportDataItem {
    id: number;
    clientId: number;
    clientName: string;
    goal: string;
    goalStep: string;
    evidenceUri?: string;
    goalStepActivity: string;
    activity: string;
    scheduledDatetime: string;
}

export interface Section {
    sectionName: string;
    data: ReportDataItem[];
}


interface ReportClientsCompleteGoalsProps {
    title: string;
}

const ReportClientsCompleteGoals: React.FC<ReportClientsCompleteGoalsProps> = ({ title }) => {
    const currentUser = userManager;
    const userRole = userManager.getUserRole();

    // State for managers and clients.
    const [managersData, setManagersData] = useState<any[]>([]);
    const [clientsData, setClientsData] = useState<any[]>([]);
    const [managerFilterId, setManagerFilterId] = useState<number | undefined>(undefined);
    const [pageSize, setPageSize] = useState(10);
    // Filters from FilterComponent.
    const [filters, setFilters] = useState({
        startDate: null as Date | null,
        endDate: null as Date | null,
        selectedManager: "",
        selectedClient: "All"
    });

    // Flat events fetched from the API.
    const [flatEvents, setFlatEvents] = useState<ReportDataItem[]>([]);
    // Grouped sections (grouped by clientName).
    const [groupedSections, setGroupedSections] = useState<Section[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);

    // Modal state for full-screen image view.
    const [modalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");

    // Helper: Format a Date to "YYYY-MM-DD HH:mm:ss"
    const formatDate = (date: Date | null): string => {
        if (!date) return "";
        const pad = (n: number) => n.toString().padStart(2, "0");
        return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
    };

    // Fetch managers.
    const fetchManagers = async () => {
        if (userRole === UserRoleKeys.RoleAdmin) {
            try {
                const response = await getManagers(1);
                if (response.success) {
                    setManagersData(response.data.managers);
                    if (response.data.managers.length > 0) {
                        const firstManagerId = response.data.managers[0].id;
                        setManagerFilterId(firstManagerId);
                        setFilters(prev => ({ ...prev, selectedManager: firstManagerId.toString() }));
                    }
                } else {
                    toast.error(response.error || "Failed to fetch managers.");
                }
            } catch (error) {
                toast.error("Failed to fetch managers.");
            }
        }
        else if (userRole === UserRoleKeys.RoleManager)
        {
            const user = currentUser.getUser();
            setManagerFilterId(user?.dbUser.id);
        }
    };

    // Fetch clients.
    const fetchClients = async () => {
        let allClients: any[] = [...clientsData]; // ✅ Preserve existing clients
        let offset = 0;
        const limit = 50;
        let hasMoreData = true;

        setLoading(true); // ✅ Show loading state before fetching

        try {
            const user = currentUser.getUser();
            const firstGroupId =
                user?.dbUser?.groupIds?.length ? user.dbUser.groupIds[0] : null;
            const filteredGroupId = managersData.find(item => item.id === managerFilterId)?.groupIds?.[0];

            while (hasMoreData) {
                const response = await getClients(1, offset, limit, currentUser.isManager() ? firstGroupId : filteredGroupId);

                if (response.success) {
                    const newClients = response.data.clients || [];

                    allClients = [...allClients, ...newClients]; // ✅ Append new data
                    offset += limit;

                    if (newClients.length < limit) {
                        hasMoreData = false; // ✅ Stop when last batch is smaller than `limit`
                    }
                } else {
                    toast.error(response.error || "Failed to fetch clients.");
                    hasMoreData = false;
                }
            }

            setClientsData(allClients); // ✅ Update state with all clients
        } catch (error) {
            toast.error("Failed to fetch clients.");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchManagers();
    }, [userRole]);

    useEffect(() => {
        fetchClients();
    }, [userRole, managerFilterId, managersData]);

    const handleFilterChange = useCallback((newFilters: typeof filters) => {
        setFilters(newFilters);
    }, []);

    useEffect(() => {
        if (filters.selectedManager !== "") {
            const newManagerId = parseInt(filters.selectedManager, 10);
            if (newManagerId !== managerFilterId) {
                setManagerFilterId(newManagerId);
            }
        }
    }, [filters.selectedManager, managerFilterId]);

    // Fetch events and format them.
    useEffect(() => {
        if (!filters.startDate || !filters.endDate || managerFilterId === undefined || isLoading) return;

        const fetchReportData = async () => {
            setLoading(true);

            const formattedStartDate = filters.startDate
                ? format(new Date(filters.startDate), "yyyy-MM-dd")
                : format(new Date(), "yyyy-MM-dd"); // Default to today if null

            const formattedEndDate = filters.endDate
                ? format(new Date(filters.endDate), "yyyy-MM-dd")
                : format(new Date(), "yyyy-MM-dd"); // Default to today if null

                    const params: any = {
                        startDate: formattedStartDate,
                        endDate: formattedEndDate,
                        complete: 1,
                        offset: 0,
                        limit: 50
                    };

                    let   groupId: number | null = null;
         if (userRole === UserRoleKeys.RoleManager)
            {
                const user = currentUser.getUser();
                const firstGroupId =
                    user &&
                    user.dbUser &&
                    Array.isArray(user.dbUser.groupIds) &&
                    user.dbUser.groupIds.length > 0
                        ? user.dbUser.groupIds[0]
                        : null;
                groupId = firstGroupId;
            }
         else
         {
             // ✅ Extract the correct `groupId` from the manager
             const selectedManager = managersData.find(manager => manager.id === managerFilterId);
              groupId = selectedManager?.groupIds?.[0]; // ✅ Get the first `groupId`
         }



            if (filters.selectedClient !== "All") {
                params.clientId = filters.selectedClient;
            }
            else {
                params.groupId = groupId;
            }
            console.log("API Params:", params);

            try {
                const response = await getMoreEventsApi( params);
                const events = response.data.events;
                const formattedEvents: ReportDataItem[] = events.map((event: any) => {
                    const client = clientsData.find((c: any) => c.id === event.clientId);
                    return {
                        id: event.scheduleId,
                        clientId: event.clientId,
                        clientName: client ? client.name : "Unknown Client",
                        goal: event.goal?.title || "Testing",
                        goalStep: event.goalStep?.title || "Image",
                        evidenceUri: event.evidenceUri,
                        goalStepActivity: event.goalStepActivity?.title || "Activity",
                        activity: event.goalStepActivity?.description || "",
                        scheduledDatetime: event.scheduledDatetime,
                        completedAt: event.createdAt
                    };
                });
                setFlatEvents(formattedEvents);
            } catch (error) {
                toast.error("Failed to fetch events.");
            } finally {
                setLoading(false);
            }
        };

        fetchReportData();
    }, [
        filters.startDate,
        filters.endDate,
        managerFilterId,
        filters.selectedClient,
        managersData,
        clientsData
    ]);

    useEffect(() => {
        if (flatEvents.length === 0) {
            setGroupedSections([]);
            return;
        }
        const paginatedData = flatEvents;//.slice(0, pageSize);
        const grouped = paginatedData.reduce((acc: { [key: string]: ReportDataItem[] }, event) => {
            if (!acc[event.clientName]) {
                acc[event.clientName] = [];
            }
            acc[event.clientName].push(event);
            return acc;
        }, {});

        const sections: Section[] = Object.entries(grouped).map(([clientName, events]) => ({
            sectionName: `${clientName}`,
            data: events,
        }));

        setGroupedSections(sections);
    }, [flatEvents]);



    // Function to download the image
    const handleDownloadImage = (url: string, filename: string) => {
        if (!url) return;
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = filename || "downloaded-image.jpg";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(() => toast.error("Failed to download image."));
    };

// Define table columns with new "Download" column
    const columns = [
        { header: "Activity", accessorKey: "goalStepActivity", enableColumnFilter: false, enableSorting: true },
        {
            header: "Description",
            accessorKey: "activity",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cellProps: any) => {
                const description = cellProps.getValue();
                return (
                    <div style={{ whiteSpace: "pre-line", wordBreak: "break-word", maxWidth: "300px" }}>
                        {description && description.trim() ? description : "-"}
                    </div>
                );
            },
        },
        { header: "Scheduled Date and Time", accessorKey: "scheduledDatetime", enableColumnFilter: false, enableSorting: true },
        { header: "Completed Date and Time", accessorKey: "completedAt", enableColumnFilter: false, enableSorting: true },
        {
            header: "Image",
            accessorKey: "evidenceUri",
            cell: (cellProps: any) => {
                const uri = cellProps.getValue();
                if (!uri) return null; // ✅ Hide the image cell if no evidenceUri

                return (
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setModalImage(uri);
                            setModalOpen(true);
                        }}
                    >
                        <CachedImage src={uri} alt="Event Evidence"  id={`${cellProps.row.original.id}-${Date.now()}`}  />
                    </div>
                );
            },
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            header: "Download",
            accessorKey: "evidenceUri",
            cell: (cellProps: any) => {
                const uri = cellProps.getValue();
                if (!uri) return null; // ✅ Do not render the button if no evidenceUri

                return (
                    <button
                        onClick={() =>
                            handleDownloadImage(uri, `evidence-${cellProps.row.original.id}.jpg`)
                        }
                        className="btn btn-sm btn-primary"
                    >
                        Download
                    </button>
                );
            },
            enableColumnFilter: false,
            enableSorting: false,
        },
    ];

//meta title
    document.title = `${title} | Eckerd Connects - Reports`;

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Report" breadcrumbItem={title} />
                <Row className="mb-4">
                    <Col lg={12}>
                        <Card className="shadow-sm">
                            <CardBody>
                                <FilterComponent
                                    isAdmin={userRole === UserRoleKeys.RoleAdmin}
                                    managers={managersData}
                                    clients={clientsData}
                                    onFilterChange={handleFilterChange}
                                    defaultSelectedManager={managerFilterId ? managerFilterId.toString() : ""}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody style={{ overflowY: "auto", maxHeight: "70vh" }}> {/* Enables vertical scrolling */}
                                {isLoading ? (
                                    <Spinners setLoading={setLoading} />
                                ) : groupedSections.length === 0 ? ( // ✅ Show message when no data
                                    <div className="text-center my-4">
                                        <h5>No Results Found</h5>
                                        <p>There are no activity reports within the selected date range.</p>
                                    </div>
                                ) : (
                                    <TableContainer
                                        columns={columns}
                                        // showExportButtons={true}
                                        data={flatEvents}  // ✅ Pass the actual data
                                        sections={groupedSections.map((section) => ({
                                            title: section.sectionName,
                                            filterFn: (row) => section.data.includes(row), // ✅ Convert sections into filter functions
                                        }))}
                                        isGlobalFilter={true}
                                        isPagination={true}
                                        isCustomPageSize={true}
                                        SearchPlaceholder="Search reports..."
                                        pagination="pagination"
                                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                        tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                        theadClass="table-light"
                                        // customRightTools={dateFilterComponent}
                                        onPageSizeChange={setPageSize}
                                    />


                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)} centered size="lg">
                    <ModalHeader toggle={() => setModalOpen(false)}>Image Preview</ModalHeader>
                    <ModalBody className="p-0" style={{ textAlign: "center" }}>
                        <img
                            src={modalImage}
                            alt="Full Screen Evidence"
                            style={{ width: "100%", height: "auto", display: "block" }}
                        />
                    </ModalBody>
                    {/* ✅ Footer to Keep Download Button Separate */}
                    <div
                        style={{
                            padding: "12px",
                            textAlign: "center",
                            borderTop: "1px solid #ddd",
                            backgroundColor: "#f9f9f9",
                        }}
                    >
                        {modalImage && (
                            <button
                                onClick={() => {
                                    const link = document.createElement("a");
                                    link.href = modalImage;
                                    link.download = `preview_image.jpg`; // ✅ Meaningful filename
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                }}
                                style={{
                                    backgroundColor: "#005f9a",
                                    color: "white",
                                    border: "none",
                                    padding: "8px 14px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    fontSize: "14px",
                                    transition: "background 0.3s",
                                }}
                                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#0056b3")}
                                onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#005f9a")}
                            >
                                Download Image
                            </button>
                        )}
                    </div>
                </Modal>

            </Container>
        </div>
    );
};

export default ReportClientsCompleteGoals;
