import React, {useEffect, useMemo, useState} from "react";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import {deleteCorpAdmin, getCorps, sendNewInvitation} from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import {
    Card,
    CardBody,
    Col,
    Container, DropdownItem, DropdownMenu,
    DropdownToggle,
    Modal,
    ModalHeader,
    Row,
    UncontrolledDropdown,
    UncontrolledTooltip
} from "reactstrap";
import Spinners from "../../../Components/Common/Spinner";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import InviteModal from "../../../Components/Modal/InviteModal";
import DeleteModal from "Components/Modal/DeleteModal";
import { UserRoleKeys } from "utils/UserManager";
import { Link } from "react-router-dom";
import { convertUTC2Local } from "utils";
interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}


const CorporatePage = ({title}) => {
    const [corpsData, setCorpsData] = useState<any[]>([]);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const [modal_standard, setmodal_standard] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();
    function tog_standard() {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }
    function toggleNewModal() {
        setNewModalIsOpen(!newModalIsOpen);
        removeBodyCss();
    }
    function toggleDeleteModal () {
        setDeleteModalIsOpen(!deleteModalIsOpen);
        removeBodyCss();
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleCreateInvite = (newInvite: any) => {
        const createInvite = async () => {
            try {
                const response = await sendNewInvitation(UserRoleKeys.RoleCorp, newInvite); // Add parameters if needed

                if (response.success) {
                    toast.success("New invitation sent.");
                    tog_standard();
                } else {
                    // Display error notification

                    const errorMessage = response.error
                        ? response.error.charAt(0).toUpperCase() + response.error.slice(1)
                        : "Failed to send new invite.";
                    toast.error(errorMessage);
                }
            } catch (error: any) {

                const errorMessage = error.message
                    ? error.message.charAt(0).toUpperCase() + error.message.slice(1)
                    : "Failed to send new invite.";

                toast.error(errorMessage);
            }
        };

        createInvite();
    }

    const handleConfirmDelete = () => {
        const removeCorpAdmin = async () => {
            try {
                const response = await deleteCorpAdmin(valueToBeModified.id); // Add parameters if needed
                if (response.success) {

                    setCorpsData((state) => [...state.filter(item => item.id !== valueToBeModified.id)]);
                    toggleDeleteModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to remove corp admin.");
                }
            } catch (error) {

                toast.error("Failed to remove corp admin.");
            }
        }

        if (!isNaN(valueToBeModified?.id)) {
            removeCorpAdmin()
        }
    }

    useEffect(() => {
        const fetchCorporate = async () => {
            try {
                const response = await getCorps(1); // Add parameters if needed
                if (response.success) {

                    setCorpsData(response.data.corpAdmins);
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to fetch managers.");
                }
            } catch (error) {

                toast.error("Failed to fetch managers.");
            }
        };

        fetchCorporate();
    }, []);
    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Email',
                accessorKey: 'email',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Name',
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Role',
                accessorKey: 'role',
                cell: (info) => <RoleCell value={info.getValue()} />,
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Phone Number',
                accessorKey: 'phoneNumber',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps: any) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link to="#" className="text-danger" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleDeleteModal()
                            }}>
                            <i className="mdi mdi-delete font-size-18" id="deleteTooltip" />
                            <UncontrolledTooltip placement="top" target="deleteTooltip">
                                Delete
                            </UncontrolledTooltip>
                            </Link>
                        </div>
                    )
                }
            },
            // Add more columns as needed
        ],
        [tog_standard ]
    );



    //meta title
    document.title = `${title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                <TableContainer
                    columns={columns}
                    data={corpsData || []}
                    isGlobalFilter={true}
                    isPagination={true}
                    isAddButton={true}
                    isCustomPageSize={true}
                    handleUserClick={tog_standard}
                    SearchPlaceholder="Search corporate users..."
                    pagination="pagination"
                    buttonClass="btn btn-success btn-rounded"
                    buttonName=" Invite New Corporate Admin"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"

                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                    theadClass="table-light"
                />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                            }
                            <InviteModal isOpen={modal_standard} toggle={tog_standard} onSave={handleCreateInvite} />
                            <DeleteModal
                                isOpen={deleteModalIsOpen}
                                toggle={toggleDeleteModal}
                                onConfirmDelete={handleConfirmDelete}
                                message={<p>Are you sure you want to delete this user? This action cannot be undone.</p>}
                            />

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default CorporatePage;
