import React, {useEffect, useMemo, useState} from "react";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import {deleteClientReport, getClientsReports, getManagers} from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label, Modal,
    ModalBody,
    ModalHeader,
    Row,
    UncontrolledTooltip
} from "reactstrap";
import Spinners from "Components/Common/Spinner";
import { convertUTC2Local } from "utils";
import { Link } from "react-router-dom";
import DeleteModal from "Components/Modal/DeleteModal";
import ReportPreviewModal from "Components/Modal/ReportPreviewModal";
import Flatpickr from 'react-flatpickr';
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect"
import "flatpickr/dist/themes/material_blue.css";
import "flatpickr/dist/plugins/monthSelect/style.css"
import CachedImage from "../../../Components/Common/CachedImage";


interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}


const ReportClientPage = ({title}) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [reportsData, setReportsData] = useState<any[]>([]);
    const [reportSections, setReportSections] = useState<any[]>([]);
    const [dateFilter, setDateFilter] = useState<Date>(new Date());
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [previewModalIsOpen, setPreviewModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();
    // Modal state for full-screen image view.
    const [modalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");
    function toggleDeleteModal () {
        setDeleteModalIsOpen(!deleteModalIsOpen);
        removeBodyCss();
    }
    const [pageSize, setPageSize] = useState(10);  // ✅ Store page size
    function togglePreviewModal () {
        setPreviewModalIsOpen(!previewModalIsOpen);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleDeleteReport = () => {
        const removeReport = async () => {
            try {
                const response = await deleteClientReport(valueToBeModified.clientId, valueToBeModified.id); // Add parameters if needed
                if (response.success) {

                    setReportsData((state) => [...state.filter(item => item.id !== valueToBeModified.id)]);
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to remove report.");
                }
            } catch (error) {

                toast.error("Failed to remove report.");
            }
        }

        if (!isNaN(valueToBeModified?.id)) {
            removeReport()
        }
    }

    useEffect(() => {
        const fetchReports = async () => {
            try {
                const response = await getClientsReports(dateFilter.getFullYear(), dateFilter.getMonth() + 1);
                if (response.success) {
                    const reports = response.data.reports;

                    // Extract only the paginated data
                    const paginatedReports = reports;//.slice(0, pageSize);  // ✅ Only use paginated data

                    // Extract unique clients and create sections
                    const clientSections = Object.values(
                        paginatedReports.reduce((acc, report) => {
                            const clientName = report.client.name || "Unknown Client";
                            if (!acc[clientName]) {
                                acc[clientName] = {
                                    title: `${clientName}`,
                                    filterFn: (row) => row.client.name === clientName,
                                };
                            }
                            return acc;
                        }, {})
                    );

                    setReportsData(paginatedReports);  // ✅ Use paginated data
                    setReportSections(clientSections);
                } else {
                    setReportsData([]);
                    setReportSections([]);
                    toast.error(response.error || "No reports retrieved.");
                }
            } catch (error) {
                setReportsData([]);
                setReportSections([]);
                toast.error("No reports retrieved.");
            }
        };

        fetchReports();
    }, [dateFilter, pageSize]);  // ✅ Re-fetch when pageSize changes


    const formatPhoneNumber = (phoneNumber: string) => {
        if (!phoneNumber) return "";
        const cleaned = phoneNumber.replace(/\D/g, ""); // Remove non-numeric characters

        if (cleaned.length !== 10) return phoneNumber; // Ensure it's a 10-digit number before formatting

        return `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}-${cleaned.substring(6, 10)}`;
    };

    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },

            {
                header: "Phone Number",
                accessorKey: "phoneNumber",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (info) => formatPhoneNumber(info.getValue()), // Apply formatting here
            },
            {
                header: 'Work Status',
                accessorKey: 'reportData.work',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return cellProps.getValue() ? 'Attending' : 'Not Attending'
                }
            },
            {
                header: 'School Status',
                accessorKey: 'reportData.school',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return cellProps.getValue() ? 'Employed' : 'Not Working'
                }
            },
            {
                header: 'Support Services Status',
                accessorKey: 'reportData.supportServices',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return cellProps.getValue() ? 'Assistance Needed' : 'Nothing Needed'
                }
            },
            {
                header: 'Notes',
                accessorKey: 'notes',
                enableColumnFilter: false,
                cell: (info) => <div className="wrap-text">{info.getValue()}</div>,
                enableSorting: true,
            },
            {
                header: 'Submitted',
                accessorKey: 'createdAt',
                enableColumnFilter: false,
                enableSorting: true
            },
            {
                header: "Images",
                accessorKey: "reportFileUris",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const images = cellProps.getValue();
                    if (!images || images.length === 0) return null; // ✅ Hide cell if no images

                    return (
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: `repeat(auto-fit, minmax(80px, 1fr))`,
                            gap: "8px",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            {images.map((image: string, index: number) => (
                                <div key={index} style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center"
                                }}>
                                    {/* ✅ Clickable Image */}
                                    <div
                                        style={{
                                            width: "80px",
                                            height: "80px",
                                            overflow: "hidden",
                                            cursor: "pointer",
                                            borderRadius: "8px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            border: "1px solid #ccc",
                                        }}
                                        onClick={() => {
                                            setModalImage(image);
                                            setModalOpen(true);
                                        }}
                                    >
                                        <CachedImage
                                            src={image}
                                            alt={`Report Image ${index + 1}`}
                                            id={`${cellProps.row.original.id}-${index}`} // Unique ID

                                        />
                                    </div>

                                    {/* ✅ Download Button - Stays Below Each Image */}
                                    <button
                                        onClick={() => {
                                            const link = document.createElement("a");
                                            link.href = image;
                                            link.download = `report_image_${cellProps.row.original.id}_${index + 1}.jpg`;
                                            document.body.appendChild(link);
                                            link.click();
                                            document.body.removeChild(link);
                                        }}
                                        style={{
                                            marginTop: "5px",
                                            backgroundColor: "#005f9a",
                                            color: "white",
                                            border: "none",
                                            padding: "5px",
                                            borderRadius: "4px",
                                            cursor: "pointer",
                                            fontSize: "12px",
                                            width: "100%",
                                            maxWidth: "80px",
                                            textAlign: "center",
                                        }}
                                    >
                                        Download
                                    </button>
                                </div>
                            ))}
                        </div>
                    );
                },
            }
            ,

            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link to="#" className="text-danger" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleDeleteModal()
                            }}>
                            <i className="mdi mdi-delete font-size-18" id="deleteTooltip" />
                            <UncontrolledTooltip placement="top" target="deleteTooltip">
                                Delete
                            </UncontrolledTooltip>
                            </Link>
                        </div>
                    )
                }
            },
            // Add more columns as needed
        ],
        []
    );

    const dateFilterComponent = useMemo(() => {
        return (
            <Row className="justify-content-end">
                <Col className="d-flex align-items-center" lg="auto" md="auto">
                    <div className="p-2" style={{ margin: 'auto', textAlign: 'center', whiteSpace: 'nowrap' }}>Filter by Month</div>
                    <Flatpickr
                        type="month"
                        className="form-control"
                        value={dateFilter}
                        options={{
                            dateFormat: 'F Y', // Format to display the selected month
                            altFormat: 'F Y', // Format to display in the input field
                            altInput: true,
                            mode: 'single',
                            plugins: [
                                monthSelectPlugin({
                                    shorthand: true, //defaults to false
                                    dateFormat: "m.y", //defaults to "F Y"
                                    altFormat: "F Y", //defaults to "F Y"
                                })
                            ]
                        }}
                        onChange={(e: any) => {
                            setDateFilter(e[0])
                        }}
                    />
                </Col>
            </Row>
        )
    }, [dateFilter]);

    const exportConfig = {
        fileName: "client_reports",
        headers: [
            "ID",
            "Phone Number",
            "Work Status",
            "School Status",
            "Support Services Status",
            "Notes",
            "Submitted",
            "Images"
        ],
        getData: (row) => [
            row.id,
            formatPhoneNumber(row.phoneNumber),
            row.reportData.work ? "Attending" : "Not Attending",
            row.reportData.school ? "Employed" : "Not Working",
            row.reportData.supportServices ? "Assistance Needed" : "Nothing Needed",
            row.notes || "-",
            row.createdAt,
            row.reportFileUris ? row.reportFileUris.join(", ") : "No Images"
        ],
        excludeFields: ["ID","Images"] // ✅ Define fields to exclude in PDF export
    };


    //meta title
    document.title = `${title} | Eckerd Connects - Reports`;

    return (
        <div className="page-content">
             <ToastContainer />
             <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    showExportButtons={true}
                                                    data={reportsData || []}
                                                    sections={reportSections} // Pass sections for grouping
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isCustomPageSize={true}
                                                    SearchPlaceholder="Search reports..."
                                                    pagination="pagination"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    customRightTools={dateFilterComponent}
                                                    exportConfig={exportConfig}  // ✅ Pass export config here
                                                    onPageSizeChange={setPageSize}
                                                />

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <ReportPreviewModal
                                isOpen={previewModalIsOpen}
                                toggle={togglePreviewModal}
                                data={valueToBeModified?.reportFileUris}
                            />
                            <DeleteModal
                                isOpen={deleteModalIsOpen}
                                toggle={toggleDeleteModal}
                                message={<p>Are you sure you want to delete this report? This action cannot be undone.</p>}
                                onConfirmDelete={handleDeleteReport}
                            />
                        </div>
                    </Col>
                </Row>
                 <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)} centered size="lg">
                     <ModalHeader toggle={() => setModalOpen(false)}>Image Preview</ModalHeader>
                     <ModalBody className="p-0" style={{ textAlign: "center" }}>
                         <img
                             src={modalImage}
                             alt="Full Screen Evidence"
                             style={{ width: "100%", height: "auto", display: "block" }}
                         />
                     </ModalBody>
                     {/* ✅ Footer to Keep Download Button Separate */}
                     <div
                         style={{
                             padding: "12px",
                             textAlign: "center",
                             borderTop: "1px solid #ddd",
                             backgroundColor: "#f9f9f9",
                         }}
                     >
                         {modalImage && (
                             <button
                                 onClick={() => {
                                     const link = document.createElement("a");
                                     link.href = modalImage;
                                     link.download = `preview_image.jpg`; // ✅ Meaningful filename
                                     document.body.appendChild(link);
                                     link.click();
                                     document.body.removeChild(link);
                                 }}
                                 style={{
                                     backgroundColor: "#005f9a",
                                     color: "white",
                                     border: "none",
                                     padding: "8px 14px",
                                     borderRadius: "5px",
                                     cursor: "pointer",
                                     fontSize: "14px",
                                     transition: "background 0.3s",
                                 }}
                                 onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#0056b3")}
                                 onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#005f9a")}
                             >
                                 Download Image
                             </button>
                         )}
                     </div>
                 </Modal>


             </Container>
        </div>
    );
}
export default ReportClientPage;
