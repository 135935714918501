import React, {useEffect, useMemo, useState} from "react";
import { Link, useNavigate } from "react-router-dom";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { addCampaign, deleteCampaign, getCampaigns, updateCampaign } from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import { convertUTC2Local } from "utils";
import './styles.css';
interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}


const StandingsPage = ({title}) => {
    const [campaignsData, setCampaignsData] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [activeStatus, setActiveStatus] = useState<number>(1);

    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                const response = await getCampaigns(activeStatus); // Add parameters if needed
                if (response.success) {

                    setCampaignsData(response.data.campaigns);
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to fetch campaigns.");
                }
            } catch (error) {

                toast.error("Failed to fetch campaigns.");
            }
        };
        // fetchManagers();
        fetchCampaigns();
    }, [activeStatus]);
    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Name',
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Description',
                accessorKey: 'description',
                enableColumnFilter: false,
                cell: (info) => <div className="wrap-text">{info.getValue()}</div>,
                enableSorting: true,
            },
            {
                header: 'Start At',
                accessorKey: 'startDate',
                cell: (info) => convertUTC2Local(info.getValue()),
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'End At',
                accessorKey: 'endDate',
                cell: (info) => convertUTC2Local(info.getValue()),
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const campaignId = cellProps.row.original.id
                    return (
                        <div className="d-flex gap-3">
                            <Link to={`/standings/${campaignId}`} className="text-primary">
                            <i className="mdi mdi-eye font-size-18" id="viewTooltip" />
                            <UncontrolledTooltip placement="top" target="viewTooltip">
                                View
                            </UncontrolledTooltip>
                            </Link>
                        </div>
                    )
                }
            },
            // Add more columns as needed
        ],
        []
    );

    const statusRadioGroups = useMemo(() => {
            return (
                <Row className="justify-content-end">
                    <Col xs="auto" className="btn-group" role="state">
                        <input type="radio" className="btn-check" name="state" id="active" autoComplete="off" defaultChecked onClick={() => setActiveStatus(1)} />
                        <label className="btn btn-outline-secondary" htmlFor="active">Active</label>
    
                        <input type="radio" className="btn-check" name="state" id="history" autoComplete="off" onClick={() => setActiveStatus(0)} />
                        <label className="btn btn-outline-secondary" htmlFor="history">History</label>
                    </Col>
                </Row>
            )
        }, []);

    //meta title
    document.title = `${title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={campaignsData || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isCustomPageSize={true}
                                                    SearchPlaceholder="Search campaigns..."
                                                    pagination="pagination"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    customLeftTools={statusRadioGroups}
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default StandingsPage;
