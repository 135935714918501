import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "Components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "Components/Common/Breadcrumb";

import avatar from "../../assets/images/users/logo192.png";

import { editProfile } from "slices/thunk";
import { createSelector } from 'reselect';
import {UserRoleKeys, userManager} from "../../utils/UserManager";
import RoleCell from "../../Components/Common/RoleCell";
import { updateAdmin, updateCorpAdmin, updateManager } from "helpers/backend_helper";
import MaskedInput from "react-text-mask";
const UserProfile = () => {

  //meta title
  document.title = "Profile | Eckerd - React Admin & Dashboard Template";

  const dispatch = useDispatch<any>();
  const phoneNumberMask = [
    "(", /[1-9]/, /\d/, /\d/, ")",
    " ", /\d/, /\d/, /\d/, "-",
    /\d/, /\d/, /\d/, /\d/
  ];
  const formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return "";
    const cleaned = phoneNumber.replace(/\D/g, ""); // Remove non-numeric characters

    if (cleaned.length !== 10) return phoneNumber; // Ensure it's a 10-digit number before formatting

    return `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}-${cleaned.substring(6, 10)}`;
  };

  const [readableRole, setReadableRole] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [idx, setIdx] = useState(1);
  const [phone, setPhone] = useState("");
  const userRole = userManager.getUserRole();
  const selectProperties = createSelector(
    (state: any) => state.Profile,
    (profile) => ({
      user: profile.user,
      error: profile.error,
      success: profile.success
    })
  );

  const { error, success, user } = useSelector(selectProperties);
  useEffect(() => {
    const storeUser = userManager.getUser();
    if (storeUser) {
      setIdx(storeUser.dbUser.id);
      setName(storeUser.dbUser.name);
      setEmail(storeUser.dbUser.email);
      setPhone(formatPhoneNumber(storeUser.dbUser.phoneNumber));
      setReadableRole(storeUser.role);
    }
  }, []);


  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      username: name || "admin",
      idx: idx || 1,
      phone: phone || "",
    },

    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Name"),
      phone: Yup.string()
          .required("Please Enter Your Phone Number")
          .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"), // Ensure only digits
    }),

    onSubmit: (values) => {
      const rawPhoneNumber = values.phone.replace(/\D/g, ""); // Remove all non-numeric characters

      if (rawPhoneNumber.length !== 10) {
        validation.setFieldError("phone", "Phone number must be exactly 10 digits");
        return;
      }

      const payload = {
        name: values.username,
        phoneNumber: rawPhoneNumber, // Send only 10-digit number
      };

      if (userRole === UserRoleKeys.RoleCorp) {
        updateCorpAdmin(values.idx, payload).then(handleResponse);
      } else if (userRole === UserRoleKeys.RoleManager) {
        updateManager(payload).then(handleResponse);
      } else if (userRole === UserRoleKeys.RoleAdmin) {
        const tenantId = userManager.getUserTenant();
        updateAdmin(payload, tenantId ?? "").then(handleResponse);
      }
    },
  });

  const handleResponse = (res) => {
    if (res.success) {
      const storedUser = userManager.getUser();
      if (storedUser) {
        storedUser["dbUser"].name = res.data.name;
        storedUser["dbUser"].phoneNumber = res.data.phoneNumber;
        userManager.setUserData(storedUser);

        setName(res.data.name);
        setPhone(formatPhoneNumber(res.data.phoneNumber)); // Show formatted version to user
      }
    } else {
      validation.setFieldError("phone", "Error updating phone number");
    }
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Eckerd" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{name || "admin"}</h5>
                        <p className="mb-1">{email || ""}</p>
                        <p className="mb-0">Id no: #{idx || 0}</p>
                        <p className="mb-0">Phone no: #{phone || ""}</p>
                        <p className="mb-0"><RoleCell value={readableRole}></RoleCell></p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Change User Name</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">Name</Label>
                  <Input
                    name="username"
                    className="form-control"
                    placeholder="Enter Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username ? true : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                  ) : null}
                  {/* <Input name="idx" value={idx} type="hidden" /> */}
                </div>

                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update Name
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
              >
                <div className="form-group">
                  <Label className="form-label">Phone Number</Label>
                  <MaskedInput
                      mask={phoneNumberMask}
                      className="form-control"
                      placeholder="(123) 456-7890"
                      value={validation.values.phone || ""}
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/\D/g, ""); // Strip non-numeric characters
                        validation.setFieldValue("phone", numericValue);
                      }}
                      onBlur={validation.handleBlur}
                      render={(ref, props) => (
                          <Input
                              {...props}
                              innerRef={ref}
                              name="phone"
                              type="text"
                              invalid={!!(validation.touched.phone && validation.errors.phone)}
                          />
                      )}
                  />
                  {validation.touched.phone && validation.errors.phone ? (
                      <FormFeedback>{validation.errors.phone}</FormFeedback>
                  ) : null}
                  {/* <Input name="idx" value={idx} type="hidden" /> */}
                </div>

                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update Phone Number
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
