import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Button, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "Components/Common/Breadcrumb";
import SendNotificationForm from "Components/Form/SendNotificationForm";
import { getNotificationChannels, getNotificationGroups, sendNotification } from "helpers/backend_helper";
import { userManager, UserRoleKeys } from "../../../utils/UserManager";
import { useNavigate } from "react-router-dom";

const SendNotificationPage = ({ title }) => {
    const [channels, setChannels] = useState<any[]>([]);
    const navigate = useNavigate();
    document.title = `${title} | Eckerd Connects - Notifications`;
    const userRole = userManager.getUserRole();

    useEffect(() => {
        const fetchChannelsAndGroups = async () => {
            try {
                // Fetch channels
                const channelsResponse = await getNotificationChannels(1); // adjust parameters as needed
                let channelsData: any[] = [];
                if (channelsResponse.success) {
                    channelsData = channelsResponse.data.channels;
                } else {
                    toast.error(channelsResponse.error || "Failed to fetch channels.");
                }

                // Fetch notification groups
                const groupsResponse = await getNotificationGroups();
                if (groupsResponse.success) {
                    const groups = groupsResponse.data.notificationGroups;
                    // Supplement channels by adding each group as a channel.
                    groups.forEach((group: any) => {
                        channelsData.push({
                            id: group.id,
                            displayName: group.name,
                            channel: `push_group_id_${group.id}`,
                        });
                    });
                } else {
                    toast.error(groupsResponse.error || "Failed to fetch notification groups.");
                }

                setChannels(channelsData);
            } catch (error) {
                toast.error("Failed to fetch channels.");
            }
        };

        fetchChannelsAndGroups();
    }, []);

    const handleSendNotification = (newNotification: any) => {
        // Validate that the notification title is provided
        if (!newNotification.title || newNotification.title.trim() === "") {
            toast.error("Please enter a title.");
            return;
        }
        // Validate that at least one channel is selected
        if (!newNotification.channels || newNotification.channels.length === 0) {
            toast.error("Please select at least one channel.");
            return;
        }
        const sendNotificationApi = async () => {
            try {
                const response = await sendNotification(newNotification); // Add parameters if needed
                if (response.success) {
                    toast.success("Notification sent.");
                } else {
                    toast.error(response.error || "Failed to send notification.");
                }
            } catch (error) {
                toast.error("Failed to send notification.");
            }
        };

        sendNotificationApi();
    };

    const goToNotificationGroups = () => {
        // Replace '/notification-groups' with the actual route of your Notification Groups page
        navigate("/notification-groups");
    };

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title} />
                {userRole === UserRoleKeys.RoleManager && (
                    <Row className="mt-3" style={{ paddingBottom: "10px" }}>
                        <Col lg={12} className="text-end">
                            <Button color="primary" onClick={goToNotificationGroups}>
                                Manage Notification Groups
                            </Button>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col lg={12}>
                        <SendNotificationForm channels={channels} onSend={handleSendNotification} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SendNotificationPage;
